import genericPrivacy from './generic-privacy'
import genericSupport from './generic-support'
import genericTerms from './generic-terms'
import teamsPrivacy from './teams-privacy'
import teamsTerms from './teams-terms'
import gcgPrivacy from './gcg-privacy'

const versionWindowsEarlyBird = '3.3.8'

//Please do not change osx version, build are made manualy
const versionOsxEarlyBird = "3.3.8";

export default [
  {
    title: "Connect Me for Windows 32 bits",
    rating: 4,
    keywords: ["web", "windows", "connectme"],
    logo: "microsoft.png",
    website: "",
    description:
      "<p>The <strong>Connect Me for Windows application 32-bit</strong> is an integration feature for Windows. It allows you to use Connect Me without the need of a regular browser and to integrate Connect Me with Windows features such as a system tray icon in the task bar or Connect Me in the Start menu.</p>",
    link: {
      type: "download",
    },
    screenshots: [
      {
        url: "printscreen.jpg",
        description: "Connect Me for Windows",
      },
    ],
    versions: [
      { label: "3.2.7", url: "/app/connect-me-desktop_3.2.7_x86.msi" },
      { label: "3.2.5", url: "/app/connect-me-desktop_3.2.5_x86.msi" },
      { label: "3.2.4", url: "/app/connect-me-desktop_3.2.4_x86.msi" },
      { label: "2.0.2", url: "/app/connect-me-desktop_2.0.2_x86.msi" },
      { label: "2.0.1", url: "/app/connect-me-desktop_2.0.1_x86.msi" },
      { label: "2.0.0", url: "/app/connect-me-desktop_2.0.0_x86.msi" },
      { label: "1.15.1", url: "/app/connect-me-desktop_1.15.1_x86.msi" },
      { label: "1.12.1", url: "/app/connect-me-desktop_1.12.1_x86.msi" },
      { label: "1.11.0", url: "/app/connect-me-desktop_1.11.0_x86.msi" },
      { label: "1.10.0", url: "/app/connect-me-desktop_1.10.0_x86.msi" },
      { label: "1.9.0", url: "/app/connect-me-desktop_1.9.0_x86.msi" },
      { label: "1.8.0", url: "/app/connect-me-desktop_1.8.0_x86.msi" },
      { label: "1.7.2", url: "/app/connect-me-desktop_1.7.2_x86.msi" },
      { label: "1.7.1", url: "/app/connect-me-desktop_1.7.1_x86.msi" },
      { label: "1.7.0", url: "/app/connect-me-desktop_1.7.0_x86.msi" },
      { label: "1.5.0", url: "/app/connect-me-desktop_1.5.0_x86.msi" },
      { label: "1.4.0", url: "/app/connect-me-desktop_1.4.0_x86.msi" },
      {
        label: "3.2.7 debug",
        url: "/app/connect-me-desktop_debug_3.2.7_x86.msi",
      },
      {
        label: "3.2.5 debug",
        url: "/app/connect-me-desktop_debug_3.2.5_x86.msi",
      },
      {
        label: "3.2.4 debug",
        url: "/app/connect-me-desktop_debug_3.2.4_x86.msi",
      },
      {
        label: "3.2.0 debug",
        url: "/app/connect-me-desktop_debug_3.2.0_x86.msi",
      },
      {
        label: "2.0.2 debug",
        url: "/app/connect-me-desktop_debug_2.0.2_x86.msi",
      },
      {
        label: "2.0.1 debug",
        url: "/app/connect-me-desktop_debug_2.0.1_x86.msi",
      },
      {
        label: "2.0.0 debug",
        url: "/app/connect-me-desktop_debug_2.0.0_x86.msi",
      },
      {
        label: "1.15.1 debug",
        url: "/app/connect-me-desktop_debug_1.15.1_x86.msi",
      },
      {
        label: "1.12.1 debug",
        url: "/app/connect-me-desktop_debug_1.12.1_x86.msi",
      },
      {
        label: "1.11.0 debug",
        url: "/app/connect-me-desktop_debug_1.11.0_x86.msi",
      },
      {
        label: "1.10.0 debug",
        url: "/app/connect-me-desktop_debug_1.10.0_x86.msi",
      },
      {
        label: "1.9.0 debug",
        url: "/app/connect-me-desktop_debug_1.9.0_x86.msi",
      },
      {
        label: "1.8.0 debug",
        url: "/app/connect-me-desktop_debug_1.8.0_x86.msi",
      },
      {
        label: "1.7.2 debug",
        url: "/app/connect-me-desktop_debug_1.7.2_x86.msi",
      },
      {
        label: "1.7.1 debug",
        url: "/app/connect-me-desktop_debug_1.7.1_x86.msi",
      },
      {
        label: "1.7.0 debug",
        url: "/app/connect-me-desktop_debug_1.7.0_x86.msi",
      },
      {
        label: "1.5.0 debug",
        url: "/app/connect-me-desktop_debug_1.5.0_x86.msi",
      },
      {
        label: "1.4.0 debug",
        url: "/app/connect-me-desktop_debug_1.4.0_x86.msi",
      },
    ],
    privacy: genericPrivacy("Connect Me for Windows"),
    support: genericSupport("Connect Me for Windows"),
    terms: genericTerms("Connect Me for Windows"),
  },
  {
    title: "Connect Me for Windows 64 bits",
    rating: 4,
    keywords: ["web", "windows", "connectme"],
    logo: "microsoft.png",
    website: "",
    description:
      "<p>The <strong>Connect Me for Windows application 64-bit</strong> is an integration feature for Windows. It allows you to use Connect Me without the need of a regular browser and to integrate Connect Me with Windows features such as a system tray icon in the task bar or Connect Me in the Start menu.</p>",
    link: {
      type: "download",
    },
    screenshots: [
      {
        url: "printscreen.jpg",
        description: "Connect Me for Windows",
      },
    ],
    versions: [
      { label: "3.2.7", url: "/app/connect-me-desktop_3.2.7_x64.msi" },
      { label: "3.2.5", url: "/app/connect-me-desktop_3.2.5_x64.msi" },
      { label: "3.2.4", url: "/app/connect-me-desktop_3.2.4_x64.msi" },
      { label: "2.0.2", url: "/app/connect-me-desktop_2.0.2_x64.msi" },
      { label: "2.0.1", url: "/app/connect-me-desktop_2.0.1_x64.msi" },
      { label: "2.0.0", url: "/app/connect-me-desktop_2.0.0_x64.msi" },
      { label: "1.15.1", url: "/app/connect-me-desktop_1.15.1_x64.msi" },
      { label: "1.12.1", url: "/app/connect-me-desktop_1.12.1_x64.msi" },
      { label: "1.11.0", url: "/app/connect-me-desktop_1.11.0_x64_2.msi" },
      { label: "1.10.0", url: "/app/connect-me-desktop_1.10.0_x64.msi" },
      { label: "1.9.0", url: "/app/connect-me-desktop_1.9.0_x64.msi" },
      { label: "1.8.0", url: "/app/connect-me-desktop_1.8.0_x64.msi" },
      { label: "1.7.2", url: "/app/connect-me-desktop_1.7.2_x64.msi" },
      { label: "1.7.1", url: "/app/connect-me-desktop_1.7.1_x64.msi" },
      { label: "1.7.0", url: "/app/connect-me-desktop_1.7.0_x64.msi" },
      { label: "1.5.0", url: "/app/connect-me-desktop_1.5.0_x64.msi" },
      { label: "1.4.0", url: "/app/connect-me-desktop_1.4.0_x64.msi" },
      {
        label: "3.2.7 debug",
        url: "/app/connect-me-desktop_debug_3.2.7_x64.msi",
      },
      {
        label: "3.2.5 debug",
        url: "/app/connect-me-desktop_debug_3.2.5_x64.msi",
      },
      {
        label: "3.2.4 debug",
        url: "/app/connect-me-desktop_debug_3.2.4_x64.msi",
      },
      {
        label: "3.2.0 debug",
        url: "/app/connect-me-desktop_debug_3.2.0_x64.msi",
      },
      {
        label: "2.0.2 debug",
        url: "/app/connect-me-desktop_debug_2.0.2_x64.msi",
      },
      {
        label: "2.0.1 debug",
        url: "/app/connect-me-desktop_debug_2.0.1_x64.msi",
      },
      {
        label: "2.0.0 debug",
        url: "/app/connect-me-desktop_debug_2.0.0_x64.msi",
      },
      {
        label: "1.15.1 debug",
        url: "/app/connect-me-desktop_debug_1.15.1_x64.msi",
      },
      {
        label: "1.12.1 debug",
        url: "/app/connect-me-desktop_debug_1.12.1_x64.msi",
      },
      {
        label: "1.11.0 debug",
        url: "/app/connect-me-desktop_debug_1.11.0_x64.msi",
      },
      {
        label: "1.10.0 debug",
        url: "/app/connect-me-desktop_debug_1.10.0_x64.msi",
      },
      {
        label: "1.9.0 debug",
        url: "/app/connect-me-desktop_debug_1.9.0_x64.msi",
      },
      {
        label: "1.8.0 debug",
        url: "/app/connect-me-desktop_debug_1.8.0_x64.msi",
      },
      {
        label: "1.7.2 debug",
        url: "/app/connect-me-desktop_debug_1.7.2_x64.msi",
      },
      {
        label: "1.7.1 debug",
        url: "/app/connect-me-desktop_debug_1.7.1_x64.msi",
      },
      {
        label: "1.7.0 debug",
        url: "/app/connect-me-desktop_debug_1.7.0_x64.msi",
      },
      {
        label: "1.5.0 debug",
        url: "/app/connect-me-desktop_debug_1.5.0_x64.msi",
      },
      {
        label: "1.4.0 debug",
        url: "/app/connect-me-desktop_debug_1.4.0_x64.msi",
      },
    ],
    privacy: genericPrivacy("Connect Me for Windows"),
    support: genericSupport("Connect Me for Windows"),
    terms: genericTerms("Connect Me for Windows"),
  },
  {
    title: "Connect Me for Windows (Early Bird) 32 bits",
    rating: 4,
    keywords: ["web", "windows", "connectme"],
    logo: "microsoft.png",
    website: "",
    description:
      "<p>The <strong>Connect Me for Windows application 32-bit</strong> is an integration feature for Windows. It allows you to use Connect Me without the need of a regular browser and to integrate Connect Me with Windows features such as a system tray icon in the task bar or Connect Me in the Start menu.</p>",
    link: {
      type: "download",
    },
    screenshots: [
      {
        url: "printscreen.jpg",
        description: "Connect Me for Windows",
      },
    ],
    versions: [
      {
        label: versionWindowsEarlyBird,
        url: `/app/connect-me-desktop_${versionWindowsEarlyBird}_x86.msi`,
      },
      {
        label: `${versionWindowsEarlyBird} debug`,
        url: `/app/connect-me-desktop_debug_${versionWindowsEarlyBird}_x86.msi`,
      },
    ],
    privacy: genericPrivacy("Connect Me for Windows"),
    support: genericSupport("Connect Me for Windows"),
    terms: genericTerms("Connect Me for Windows"),
  },
  {
    title: "Connect Me for Windows (Early Bird) 64 bits",
    rating: 4,
    keywords: ["web", "windows", "connectme"],
    logo: "microsoft.png",
    website: "",
    description:
      "<p>The <strong>Connect Me for Windows application 64-bit</strong> is an integration feature for Windows. It allows you to use Connect Me without the need of a regular browser and to integrate Connect Me with Windows features such as a system tray icon in the task bar or Connect Me in the Start menu.</p>",
    link: {
      type: "download",
    },
    screenshots: [
      {
        url: "printscreen.jpg",
        description: "Connect Me for Windows",
      },
    ],
    versions: [
      {
        label: versionWindowsEarlyBird,
        url: `/app/connect-me-desktop_${versionWindowsEarlyBird}_x64.msi`,
      },
      {
        label: `${versionWindowsEarlyBird} debug`,
        url: `/app/connect-me-desktop_debug_${versionWindowsEarlyBird}_x64.msi`,
      },
    ],
    privacy: genericPrivacy("Connect Me for Windows"),
    support: genericSupport("Connect Me for Windows"),
    terms: genericTerms("Connect Me for Windows"),
  },
  {
    title: "Connect Me for MS Teams",
    rating: 4,
    keywords: ["web", "windows", "connectme"],
    logo: "teams.png",
    website: "",
    description:
      "The <b>Connect Me for MS Teams</b> is a simple yet powerful application which let you easily contact your colleagues within a Teams chat session on their phone numbers.",
    link: {
      type: "install",
      url: "https://appsource.microsoft.com/en-us/product/office/WA200001669",
    },
    screenshots: [
      {
        url: "printscreen-teams.png",
        description: "Connect Me for Windows",
      },
    ],
    privacy: teamsPrivacy("Connect Me for MS Teams"),
    support: genericSupport("Connect Me for MS Teams"),
    terms: teamsTerms("Connect Me for MS Teams"),
  },
  {
    title: "Connect Me for Mac OS (Early Bird) 64 bits",
    rating: 4,
    keywords: ["web", "MacOs", "Mac Os", "Mac", "Apple", "connectme"],
    logo: "apple.png",
    website: "",
    description:
      "<p>The <strong>Connect Me for Mac OS application 64-bit</strong> is an integration feature for Mac. It allows you to use Connect Me without the need of a regular browser and to integrate Connect Me with Mac features such as a system tray icon in the task bar or Connect Me in the Start menu.</p>",
    link: {
      type: "download",
    },
    screenshots: [
      {
        url: "printscreen.jpg",
        description: "Connect Me for MacOs",
      },
    ],
    versions: [
      {
        label: `${versionOsxEarlyBird}`,
        url: `/app/ConnectMe-installer_${versionOsxEarlyBird}.pkg`,
      },
      {
        label: `${versionOsxEarlyBird} Debug`,
        url: `/app/ConnectMe-debug-installer_${versionOsxEarlyBird}.pkg`,
      },
    ],
    privacy: genericPrivacy("Connect Me for Mac OS"),
    support: genericSupport("Connect Me for Mac OS"),
    terms: genericTerms("Connect Me for Mac OS"),
  },
  {
    title: "Connect Me for Android",
    rating: 5,
    keywords: ["web", "android", "connectme"],
    logo: "android.png",
    website: "",
    description:
      "<p>The <strong>Connect Me for Android application</strong> is an integration feature for Android. It allows you to integrate Connect Me on your Android device meaning that you will not need a regular browser to use Connect Me anymore.</p><p><em>Attention:</em> the application is only available as from version 5.0/Lollipop of Android devices.</p>",
    link: {
      type: "install",
      url: "https://play.google.com/store/apps/details?id=com.escaux.connect.mobile.full",
    },
    screenshots: [
      {
        url: "printscreen-android-1.png",
        description: "Connect Me for Android",
      },
      {
        url: "printscreen-android-2.png",
        description: "Connect Me for Android",
      },
    ],
    privacy: genericPrivacy("Connect Me for Android"),
    support: genericSupport("Connect Me for Android"),
    terms: genericTerms("Connect Me for Android"),
  },
  {
    title: "Connect Me for IOS",
    rating: 3,
    keywords: ["web", "ios", "apple", "connectme"],
    logo: "apple.png",
    website: "",
    description:
      "<p>The <strong>Connect Me for iOS application</strong> is an integration feature for iOS. It allows you to integrate Connect Me on your Apple device meaning that you will not need a regular browser to use Connect Me.</p><p><em>Attention:</em> the application is only available as from version 8 of Apple devices.</p>",
    link: {
      type: "install",
      url: "https://itunes.apple.com/us/app/connectme-to/id1045447092?mt=8",
    },
    screenshots: [
      {
        url: "printscreen-ios-1.jpg",
        description: "Connect Me for IOS",
      },
      {
        url: "printscreen-ios-2.jpg",
        description: "Connect Me for IOS",
      },
      {
        url: "printscreen-ios-3.jpg",
        description: "Connect Me for IOS",
      },
    ],
    privacy: genericPrivacy("Connect Me for IOS"),
    support: genericSupport("Connect Me for IOS"),
    terms: genericTerms("Connect Me for IOS"),
  },
  {
    title: "Connect Me screen sharing",
    rating: 4,
    keywords: ["web", "screen", "sharing", "screensharing", "connectme"],
    logo: "screensharing.png",
    website: "",
    description:
      "<p>This google chrome extensions enables screen sharing for Connect Me web application.</p>",
    link: {
      type: "install",
      url: "https://chrome.google.com/webstore/detail/connect-me-screen-sharing/mhegkpdkaemmmdgbojonlhbenfnlhena?utm_source=gmail",
    },
    screenshots: [
      {
        url: "printscreen-screensharing.png",
        description: "Connect Me screen sharing",
      },
    ],
    privacy: genericPrivacy("Connect Me screen sharing"),
    support: genericSupport("Connect Me screen sharing"),
    terms: genericTerms("Connect Me screen sharing"),
  },
  {
    title: "Connect Me for Gmail",
    rating: 5,
    keywords: ["web", "gmail", "google", "connectme"],
    logo: "gmail.png",
    website: "",
    description:
      "<p>The <strong>Connect Me for Gmail</strong> extension will allow you to have Connect Me integrated in the same window as your Gmail. Once the extension is installed, clicking on telephony numbers in your Gmail contacts will allow you to place a call with your favorite device and offer all other available Connect Me functionalities.</p>",
    link: {
      type: "install",
      url: "https://chrome.google.com/webstore/detail/connect-me-for-gmail/paegkagfiglphkhdomahhjijflbhopgk?utm_source=gmail",
    },
    screenshots: [
      {
        url: "printscreen-gmail-1.png",
        description: "Connect Me for Gmail",
      },
      {
        url: "printscreen-gmail-2.png",
        description: "Connect Me for Gmail",
      },
    ],
    privacy: genericPrivacy("Connect Me for Gmail"),
    support: genericSupport("Connect Me for Gmail"),
    terms: genericTerms("Connect Me for Gmail"),
  },
  {
    title: "Connect Me for Google Calendar",
    rating: 4,
    keywords: ["web", "google", "calendar", "connectme"],
    logo: "google-calendar.png",
    website: "https://google-calendar.connectme.io",
    description:
      "The <b>Connect Me for Google Calendar</b> is a simple yet powerful application which let you easily sync your google calendar events with your Connect Me status.",
    link: {
      type: "use",
      url: "https://google-calendar.connectme.io",
    },
    screenshots: [
      {
        url: "printscreen-gcg-1.png",
        description: "Connect Me for Google Calendar",
      },
    ],
    privacy: gcgPrivacy("Connect Me for Google Calendar"),
    support: genericSupport("Connect Me for Google Calendar"),
    terms: genericTerms("Connect Me for Google Calendar"),
  },
];
