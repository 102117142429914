import React from 'react'

import { withTranslation } from 'react-i18next'
import { MDCMenu } from '@material/menu'

import Dialog from '../dialog/dialog'
import Tabs from '../tabs/tabs'
import config from '../config/config'

class HomeDialog extends React.Component {
  constructor (props) {
    super(props)

    this.dialog = React.createRef()
    this.tabs = React.createRef()

    this.state = { open: false, section: 'preview', text: '' }
  }

  open (item, page) {
    this.item = item
    this.setState({
      open: true,
      current: item.versions && item.versions.length > 0 && item.versions[0],
      section: 'preview',
      text: item.description
    })

    const index = page && config.dialogTabs.indexOf(page)

    index && this.tabs.current.select(index)

    if (item.versions && item.versions.length > 0) {
      setTimeout(() => { this.menu = new MDCMenu(document.querySelector('.mdc-dialog .mdc-menu')) }, 200)
    }
  }

  close (action) {
    this.dialog.current.close(action)
  }

  onClose (action) {
    this.props.onClose && this.props.onClose(action)
    this.setState({ open: false, userSearch: false })
  }

  setCurrent (item) {
    this.setState({ current: item })
  }

  select (section) {
    const text = section === 'preview' ? this.item.description : this.item[section]
    this.setState({ section, text })
  }

  render () {
    const { t } = this.props
    return this.state.open && (
      <Dialog ref={this.dialog} onClosed={(action) => this.onClose(action)}>
        <h2 className='mdc-dialog__title'>{this.item.title}</h2>
        <div className='mdc-dialog__content'>
          <Tabs ref={this.tabs} items={config.dialogTabs} onSelect={item => this.select(item)} />
          {this.state.section === 'preview' && (
            <div className='mdc-dialog__preview'>
              {this.item.screenshots.map((image, index) => (
                <a key={index} href={`/assets/${image.url}`} target='_blank' rel='noopener noreferrer'>
                  <img src={`/assets/${image.url}`} alt={image.description} />
                </a>
              ))}
            </div>
          )}
          <p dangerouslySetInnerHTML={{ __html: this.state.text }} />
        </div>
        <div className='mdc-dialog__actions'>
          <button className='mdc-button mdc-button--outlined mdc-card__action mdc-card__action--button mdc-ripple-upgraded' data-mdc-dialog-action='close'>
            <span className='mdc-button__ripple' />
            <i className='material-icons mdc-button__icon'>close</i>
            <span className='mdc-button__label'>{t('close')}</span>
          </button>
          {this.item.versions && this.item.versions.length > 0 && (
            <span className='mdc-menu-surface--anchor'>
              <button
                className='mdc-button mdc-button--outlined mdc-card__action mdc-card__action--button mdc-ripple-upgraded'
                onClick={() => { this.menu.open = true }}
              >
                <span className='mdc-button__ripple' />
                <i className='material-icons mdc-button__icon'>expand_more</i>
                <span className='mdc-button__label'>{this.state.current.label || this.item.versions[0].label}</span>
              </button>
              <div className='mdc-menu mdc-menu-surface'>
                <ul className='mdc-list'>
                  {this.item.versions.map((version, index) => (
                    <li key={index} className='mdc-list-item' onClick={() => this.setCurrent(version)}>
                      <span className='mdc-list-item__text'>{version.label}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </span>
          )}
          <a
            onClick={event => { event.stopPropagation() }}
            className='mdc-button mdc-button--raised mdc-card__action mdc-card__action--button mdc-ripple-upgraded'
            download={this.item.link.type === 'download'}
            href={(this.state.current && this.state.current.url) || this.item.link.url}
            target={this.item.link.type === 'download' ? '_self' : '_blank'}
            rel='noopener noreferrer'
          >
            <span className='mdc-button__ripple' />
            <i className='material-icons mdc-button__icon'>{this.item.link.type === 'link' ? 'link' : 'file_download'}</i>
            <span className='mdc-button__label'>{t(this.item.link.type)}</span>
          </a>
        </div>
      </Dialog>
    )
  }
}

export default withTranslation('translation', { withRef: true })(HomeDialog)
