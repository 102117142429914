import React from 'react'

import { withRouter } from 'react-router-dom'

import config from '../config/config'
import Tabs from '../tabs/tabs'
import Items from './items'
import Dialog from './item-dialog'
import header from '../header/header-reference'

class Home extends React.Component {
  constructor (props) {
    super(props)

    this.tabs = React.createRef()
    this.dialog = React.createRef()
    this.items = React.createRef()

    this.state = { ready: false, users: [] }
  }

  onSelectItem (item, page) {
    this.dialog.current.open(item, page)
  }

  onSelectTab (item) {
    this.search(item)
    this.hideSearch()
  }

  search (item) {
    this.items.current && this.items.current.search(item !== 'ALL' ? item : null)
  }

  clear () {
    this.tabs.current && this.tabs.current.select(0)
  }

  hideSearch () {
    header.current && header.current.hideSearch && header.current.hideSearch()
  }

  render () {
    const { name, page } = this.props.match.params

    return [
      <Tabs key='tabs' ref={this.tabs} items={config.tabs} onSelect={item => this.onSelectTab(item)} />,
      <Items key='items'
        ref={this.items}
        name={name}
        page={page}
        onSelect={(item, page) => this.onSelectItem(item, page)}
      />,
      <Dialog key='dialog' ref={this.dialog} />
    ]
  }
}

export default withRouter(Home)
