export default (appName, additionalText = '') => `
<h2>${appName} term of usage</h2>

<p>By visiting and using our Services, you are accepting the terms of usage and consenting to the practices outlined within.</p>

<h3> General Escaux Term and conditions </h3>

<p>
  As a general principle the Escaux General conditions (see url infra) applies to <b>${appName}</b> application. However, it must be understood in this Escaux Privacy Policy that "Escaux, we or us" can also include the Platform/Services and Application which are necessary to run the <b>${appName}</b> application.
</p>
<p>This document can be found here:</p>

<a href="http://www.escaux.com/docs/GeneralConditions.html">GeneralConditions.html</a>

${additionalText}

<h3>Changes of these conditions</h3>

<p>
  These conditions are subject to change, and any changes to this will be posted on our Sites.
</p>

<h3>Contact Information</h3>

<p>
  For all question or request concerning personal data, you can send a mail to: <a href="mailto:privacy@escaux.com">privacy@escaux.com</a> or a letter at:
</p>

<p>
  Escaux sa<br>
  Privacy Department<br>
  408 Chaussée de Bruxelles<br>
  1300 Wavre
</p>
`
