import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import Favicon from 'react-favicon'

import './translations/i18n'

import config from './config/config'
import Header from './header/header'
import header from './header/header-reference'
import favicon from 'connect-me-icon/build/favicon.ico'

import './index.scss'
import './common/common.scss'
import '@material/typography/mdc-typography.scss'
import '@material/elevation/mdc-elevation.scss'

let component = {}

const search = (filter) => component.search && component.search(filter)
const clear = () => component.clear && component.clear()

ReactDOM.render(
  <Router>
    <Favicon url={favicon} />
    <Header ref={header} clear={() => clear()} search={(filter) => search(filter)} />
    <main className='main-content'>
      <Switch>
        {config.routes.map((route, index) =>
          <Route key={index} exact={route.exact} path={route.path}>
            <route.component wrappedComponentRef={c => (component = c)} />
          </Route>
        )}
      </Switch>
    </main>
  </Router>,
  document.querySelector('#root')
)
