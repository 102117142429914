import Home from '../home/home'

export default {
  tabs: ['ALL', 'WINDOWS', 'MACOS', 'ANDROID', 'IOS'],
  dialogTabs: ['preview', 'terms', 'privacy', 'support'],
  routes: [
    { path: '/:name', exact: true, component: Home },
    { path: '/:name/:page', exact: true, component: Home },
    { path: '*', exact: false, component: Home }
  ]
}
