import React from 'react'

import { withTranslation } from 'react-i18next'

import data from '../data/data'

class Items extends React.Component {
  constructor (props) {
    super(props)

    this.state = { items: data }
  }

  componentDidMount () {
    const { name, page } = this.props

    const item = name && data.find(item => item.title.replace(/ /g, '+').toLowerCase() === name)
    setTimeout(() => item && this.onSelect(item, page), 200)
  }

  onSelect (item, page) {
    this.props.onSelect && this.props.onSelect(item, page)
  }

  filter (item, filter) {
    return item.keywords.some(keyword => keyword.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
  }

  search (filter) {
    this.setState({ filter, items: (filter ? data.filter(item => this.filter(item, filter)) : data) })
  }

  render () {
    const { t } = this.props
    return (
      <div className='mdc-layout-grid'>
        <div className='mdc-layout-grid__inner'>
          {this.state.items.map((item, index) => (
            <div
              className='mdc-card elevated pointer mdc-layout-grid__cell mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-6-desktop'
              key={index}
              onClick={() => this.onSelect(item)}
            >
              <div className='mdc-card__content'>
                <h2 className='mdc-typography mdc-typography--headline6'>{item.title}</h2>
                <img src={require(`connect-me-icon/build/store/${item.logo}`)} alt={item.title} />
                <span className='mdc-typography mdc-typography--body2' dangerouslySetInnerHTML={{ __html: item.description }} />
              </div>
              <div className='mdc-card__actions'>
                <div className='mdc-card__action-icons'>
                  <a
                    onClick={event => { event.stopPropagation() }} className='mdc-button mdc-button--raised mdc-card__action mdc-card__action--button mdc-ripple-upgraded'
                    download={item.link.type === 'download'}
                    href={item.versions ? item.versions[0].url : item.link.url}
                    target={item.link.type === 'download' ? '_self' : '_blank'}
                    rel='noopener noreferrer'
                  >
                    <span className='mdc-button__ripple' />
                    <i className='material-icons mdc-button__icon'>{item.link.type === 'link' ? 'link' : 'file_download'}</i>
                    <span className='mdc-button__label'>{t(item.link.type)}</span>
                  </a>
                </div>
              </div>
            </div>)
          )}
          {this.state.items.length <= 0 && (
            <div className='mdc-card mdc-layout-grid__cell mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop'>
              <div className='mdc-card__content no-content'>
                <p className='mdc-typography mdc-typography--body1'>
                  <i className='material-icons'>warning</i>
                  {t('noResult')} "<b>{this.state.filter}</b>"
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default withTranslation('translation', { withRef: true })(Items)
