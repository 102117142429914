import genericPrivacy from './generic-privacy'

export default (appName) => genericPrivacy(appName, `
<h2>Specific Privacy Policy informations related to ${appName}</h2>
<p>To integrate with a third-party service, ${appName} needs your authorization to communicate with the service's application programming interface (API). A majority of third-party services employ OAuth protocol for this purpose, which enables ${appName} to communicate with third-party services on your behalf <b>without storing your credentials</b>. The only sensitive information stored is <b>OAuth access and refresh tokens</b>.</p>
<p><b>The Google Calendar Gateway</b> is an application used to link your Google Calendar events whith your intentional status on <b> Connect Me </b>. Therefor, <b> Google Calendar Gateway </b> requests read access to your Google Calendar user data. </p>
<p> <b> The data </b> that <b> Google Calendar Gateway </b> requests is a read access to your calendar. This includes the time and duration of your events as well as additional information of the event like the title, description, status, location. The data that this application uses are the time and duration of an event as well as the title, description and status of the event. <b> Google Calendar Gateway </b> only has a read access to your google calendar data. At no time it will modify your events our store your data.</p>
<p> Google Calendar Gateway is <b> requesting your Google Calendar user data </b> to be able to notify the <b> Connect Me </b> application to change your status during your events. </p>
`)

