export default (appName) => `
<h2>Support for ${appName}</h2>

<h3>Open a ticket by sending an email to <a href="mailto:support@escaux.com">support@escaux.com</a></h3>

<p>It’s important to have a clear problem description.</p>

<p>
  Name:<br>
  Phone number:<br>
  Company:<br>
  Customer number:<br>
  Priority : Very High / High / Medium / Low<br>
  Problem description:<br>
  Troubleshooting realized:<br>
</p>
<p>You will receive an automated email containing your ticket number. Please always answer to this email.</p>
`
