import React from 'react'
import { withTranslation } from 'react-i18next'

import './header.scss'

class Header extends React.Component {
  constructor (props) {
    super(props)

    this.input = React.createRef()

    this.state = { showSearch: false }
  }

  showSearch () {
    this.clear()
    this.setState({ showSearch: true })
    setTimeout(() => this.input.current.focus(), 200)
  }

  hideSearch () {
    this.setState({ showSearch: false })
    this.input.current.value = ''
  }

  clear () {
    this.props.clear && this.props.clear()
  }

  onChange () {
    if (this.props.search) {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => this.props.search(this.input.current && this.input.current.value), 500)
    }
  }

  render () {
    const { t } = this.props
    return (
      <header className='mdc-top-app-bar mdc-elevation--z1'>
        <div className='mdc-top-app-bar__row'>
          <section className='mdc-top-app-bar__section mdc-top-app-bar__section--align-start'>
            <span className='logo' title='Connect Me' />
            <span className='mdc-top-app-bar__title'>Connect Me</span>
            <input ref={this.input} type='text' placeholder={t('search')} className={!this.state.showSearch ? 'hidden' : ''} onChange={() => this.onChange()} />
          </section>
          <section className='mdc-top-app-bar__section mdc-top-app-bar__section--align-end' role='toolbar'>
            {!this.state.showSearch && <div className='material-icons mdc-top-app-bar__action-item pointer'
              onClick={() => this.showSearch()}
              aria-label='Search'
              alt='Search'
            >
              search
            </div>}
            {this.state.showSearch && <div className='material-icons mdc-top-app-bar__action-item close pointer'
              onClick={() => this.clear()}
              aria-label='Close search'
              alt='Close search'
            >
              close
            </div>}
          </section>
        </div>
      </header>
    )
  }
}

export default withTranslation('translation', { withRef: true })(Header)
