export default (appName, additionalText = '') => `
<h2>Privacy policy for ${appName}</h2>

<p>By visiting and using our Services, you are accepting the terms in this policy and consenting to the practices outlined within.
</p>

<h3>Privacy policy for ${appName} as an add-in on General Escaux Privacy Policy.</h3>

<p>
This privacy policy for ${appName} is structured as an add-on on general Escaux Privacy Policy which describes the overall policy for all Escaux services.
The add-in described here under specifies important topics like change of this policy, Contact informations and specific data collected to run this specific service.

</p>

<h3>General Escaux Privacy Policy</h3>

<p>As a general principle, the Escaux Privacy Policy (see url infra) applies to <b>${appName}</b> application. However, it must be understood in this Escaux Privacy Policy that "Escaux, we or us" can also include the Platform/Services and Application which are necessary to run the <b>${appName} application</b>.</p>

<p>This general Escaux Privacy Policy can be found here:</p>

<a href="http://www.escaux.com/rsrc/EscauxCustomerDocs/ContractInfo/EscauxPrivacyCodeOfConduct.pdf">EscauxPrivacyCodeOfConduct.pdf</a>

${additionalText}

<h3>Changes of this policy</h3>

<p>This Policy is subject to change, and any changes to this Policy will be posted on our Sites.</p>

<h2>Contact Information</h2>

<p>For all question or request concerning personal data, you can send a mail to <a href="mailto:privacy@escaux.com">privacy@escaux.com</a> or a letter at:</p>

<p>
  Escaux sa<br>
  Privacy Department<br>
  408 Chaussée de Bruxelles<br>
  1300 Wavre<br>
</p>
`
