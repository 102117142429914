export default {
  translation: {
    download: 'Download',
    install: 'Install',
    link: 'Use',
    noResult: 'There are no results for',
    preview: 'preview',
    terms: 'terms of use',
    privacy: 'privacy policy',
    support: 'support'
  }
}
