import React from 'react'
import { withTranslation } from 'react-i18next'
import { MDCTabBar } from '@material/tab-bar'

import './tabs.scss'

class Tabs extends React.Component {
  constructor (props) {
    super(props)

    this.element = React.createRef()
  }

  componentDidMount () {
    this.tabs = new MDCTabBar(this.element.current)
    this.tabs.focusOnActivate = false

    this.tabs.activateTab(0)
  }

  select (index) {
    this.tabs.activateTab(index)
    this.props.onSelect && this.props.onSelect(this.props.items[index])
  }

  render () {
    const { t, items } = this.props
    return (
      <div ref={this.element} className='mdc-tab-bar' role='tablist'>
        <div className='mdc-tab-scroller'>
          <div className='mdc-tab-scroller__scroll-area'>
            <div className='mdc-tab-scroller__scroll-content'>
              {items.map((item, index) =>
                <button
                  onClick={() => this.select(index)}
                  className='mdc-tab'
                  role='tab'
                  aria-selected='true'
                  key={index}>
                  <span className='mdc-tab__content'>
                    <span className='mdc-tab__text-label'>{t(item)}</span>
                  </span>
                  <span className='mdc-tab-indicator'>
                    <span className='mdc-tab-indicator__content mdc-tab-indicator__content--underline' />
                  </span>
                  <span className='mdc-tab__ripple' />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation('translation', { withRef: true })(Tabs)
